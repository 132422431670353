<template>
  <div v-if="displayType === 'calendar'" class="calendar-container">
    <a-modal
      v-model="visibleModalManageKegiatan"
      class="mod"
      :title="status.isEdit ? 'Detail Kegiatan' : 'Form Tambah Kegiatan'"
      @cancel="toggleModalManageKegiatan"
      :destroyOnClose="true"
      centered
    >
      <form-kegiatan
        :dataKegiatan="newKegiatan"
        :isDisabled="true"
      />
      <template slot="footer">
        <div class="d-none">
        </div>
      </template>
    </a-modal>
    <calendar-kegiatan
      :listKegiatan="listKegiatan"
      :listPeriode="listPeriode"
      :listShift="listShift"
      :listKehadiran="listKehadiran"
      @selected-date="handleSelectDate"
    />
  </div>
  <div v-else>
    <div class="pb-5">
    <a-range-picker @change="onChangeDatePicker" />
  </div>
  <div>
    <a-table
      :columns="kehadiranColumns"
      :data-source="dataTableLogKehadiran"
      :scroll="{ x: 1000, y: '' }"
      :loading="loadingTable"
      bordered
    >d
      <span slot="late" slot-scope="text">
        <a-tag v-if="text !== '-'" color='#f70000' size="large">
            {{ text }}
        </a-tag>
        <div v-else>
          {{ text }}
        </div>
      </span>
      <span slot="statusTags" slot-scope="statusTags">
        <a-tag
          v-for="tag in statusTags"
          :key="tag"
          :color="tag === 'Hadir' ? 'green' : tag === 'Tidak Hadir' || tag === 'Terlambat' ? 'volcano' : 'geekblue'"
        >
          {{ tag }}
        </a-tag>
      </span>
    </a-table>
  </div>
  </div>
</template>

<script>
import moment from 'moment'
const FormKegiatan = () => import('@/components/app/AdminHR/Kegiatan/FormKegiatan')
const CalendarKegiatan = () => import('@/components/app/Shared/CalendarKegiatan')

const kehadiranColumns = [
  {
    title: 'No',
    dataIndex: 'key',
    sorter: (a, b) => a.key - b.key,
    align: 'center',
    width: 100,
    fixed: 'left',
  },
  {
    title: 'Tanggal',
    dataIndex: 'tanggal',
    align: 'center',
    width: 200,
    fixed: 'left',
  },
  {
    title: 'Detail Masuk',
    align: 'center',
    children: [
      {
        title: 'Jam',
        dataIndex: 'timeIn',
        align: 'center',
        width: 250,
      },
      {
        title: 'Alamat IP',
        dataIndex: 'ipAddressIn',
        align: 'center',
        width: 250,
      },
      {
        title: 'Lokasi',
        dataIndex: 'locationIn',
        align: 'center',
        width: 250,
      },
      {
        title: 'Foto',
        dataIndex: 'photoIn',
        align: 'center',
        width: 250,
      },
      {
        title: 'Browser',
        dataIndex: 'browserIn',
        align: 'center',
        width: 250,
      },
      {
        title: 'Perangkat',
        dataIndex: 'deviceIn',
        align: 'center',
        width: 250,
      },
      {
        title: 'OS',
        dataIndex: 'osIn',
        align: 'center',
        width: 250,
      },
    ],
  },
  {
    title: 'Detail Keluar',
    align: 'center',
    children: [
      {
        title: 'Jam',
        dataIndex: 'timeOut',
        align: 'center',
        width: 250,
      },
      {
        title: 'Alamat IP',
        dataIndex: 'ipAddressOut',
        align: 'center',
        width: 250,
      },
      {
        title: 'Lokasi',
        dataIndex: 'locationOut',
        align: 'center',
        width: 250,
      },
      {
        title: 'Foto',
        dataIndex: 'photoOut',
        align: 'center',
        width: 250,
      },
      {
        title: 'Browser',
        dataIndex: 'browserOut',
        align: 'center',
        width: 250,
      },
      {
        title: 'Perangkat',
        dataIndex: 'deviceOut',
        align: 'center',
        width: 250,
      },
      {
        title: 'OS',
        dataIndex: 'osOut',
        align: 'center',
        width: 250,
      },
    ],
  },
  {
    title: 'Detail Shift',
    align: 'center',
    children: [
      {
        title: 'Hari',
        dataIndex: 'dayShift',
        align: 'center',
        width: 250,
      },
      {
        title: 'Jam Masuk',
        dataIndex: 'timeInShift',
        align: 'center',
        width: 250,
      },
      {
        title: 'Jam Keluar',
        dataIndex: 'timeOutShift',
        align: 'center',
        width: 250,
      },
      {
        title: 'Toleransi',
        dataIndex: 'toleranceShift',
        align: 'center',
        width: 250,
      },
    ],
  },
  {
    title: 'Terlambat',
    dataIndex: 'late',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'late' },
  },
  {
    title: 'Status',
    dataIndex: 'statusTags',
    align: 'center',
    width: 300,
    fixed: 'right',
    scopedSlots: { customRender: 'statusTags' },
  },
]

export default {
  name: 'Kegiatan',
  components: {
    FormKegiatan,
    CalendarKegiatan,
  },
  props: {
    displayType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      kehadiranColumns,
      loadingTable: false,
      visibleModalManageKegiatan: false,
      dataTableLogKehadiran: [],
      listKegiatan: [],
      listPeriode: [],
      listShift: null,
      listKehadiran: null,
      status: {
        isEdit: false,
        isCreate: false,
      },
      newKegiatan: {
        jenis: null,
        nama: null,
        deskripsi: null,
        tanggal_awal: null,
        tanggal_akhir: null,
        status_pengulangan: 'inactive',
        pengulangan_sampai: null,
      },
      idEdit: null,
      startDate: null,
      endDate: null,
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      this.newKegiatan[column] = value
      if (column === 'jenis') {
        if (value === 'libur') {
          this.newKegiatan.tanggal_akhir = null
        } else if (value === 'acara') {
          this.newKegiatan.status_pengulangan = 'inactive'
          this.newKegiatan.pengulangan_sampai = null
        }
      }
      if (column === 'status_pengulangan') {
        if (value === 'inactive') {
          this.newKegiatan.pengulangan_sampai = null
        }
      }
      console.log(this.newKegiatan)
    },
    toggleModalManageKegiatan(status, data) {
      // console.log(status)
      this.visibleModalManageKegiatan = !this.visibleModalManageKegiatan
      if (!this.visibleModalManageKegiatan) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newKegiatan = {
            jenis: null,
            nama: null,
            deskripsi: null,
            tanggal_awal: null,
            tanggal_akhir: null,
            status_pengulangan: 'inactive',
            pengulangan_sampai: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newKegiatan = {
            jenis: data.jenis,
            nama: data.nama,
            deskripsi: data.deskripsi,
            tanggal_awal: moment(data.tanggal_awal),
            tanggal_akhir: moment(data.tanggal_akhir),
            status_pengulangan: data.status_pengulangan,
            pengulangan_sampai: data.pengulangan_sampai,
          }
          console.log(this.newKegiatan)
        }
      }
    },
    handleSelectDate(date) {
      this.toggleModalManageKegiatan('edit', date)
    },
    fetchDataKegiatan() {
      this.$store.dispatch('kegiatan/FETCH_KEGIATAN')
        .then(res => {
          this.listKegiatan = res
        })
    },
    fetchDataShiftPegawai() {
      this.$store.dispatch('shift/FETCH_SHIFT_PEGAWAI', { idPegawai: this.user.id })
        .then(res => {
          this.listShift = res.shiftPegawai
          this.listKehadiran = res.listKehadiran
        })
    },
    fetchPeriode() {
      this.$store.dispatch('absensiKaryawan/FETCH_PERIODE_KEHADIRAN', { page: 'all' })
        .then(res => {
          this.listPeriode = res || []
        })
    },
    fetchDataTable() {
      this.loadingTable = true
      this.$store.dispatch('absensiKaryawan/FETCH_ATTENDANCE_LOG', { idPegawai: this.user.id, startDate: this.startDate, endDate: this.endDate })
        .then(res => {
          this.dataTableLogKehadiran = res
          this.loadingTable = false
        })
    },
    onChangeDatePicker(date, dateString) {
      this.loadingTable = true
      // console.log(date, dateString)
      this.startDate = dateString[0]
      this.endDate = dateString[1]
      this.fetchDataTable()
      this.loadingTable = false
    },
  },
  mounted() {
    this.fetchDataKegiatan()
    this.fetchDataShiftPegawai()
    this.fetchPeriode()
    this.fetchDataTable()
  },
  computed: {
    user() { return this.$store.state.user.user },
  },
}
</script>

<style lang="css">
.calendar-container {
  position: relative;
  margin-top: -11px;
}

/* .calendar-container .button-tambah {
  position: absolute;
  top: 12px;
  right: 0;
} */
</style>
